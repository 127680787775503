@font-face {
  font-family: "Figtree";
  src: url("Figtree-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Figtree Bold";
  src: url("Figtree-Bold.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Figtree Semibold";
  src: url("Figtree-SemiBold.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Figtree Medium";
  src: url("Figtree-Medium.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Figtree Light";
  src: url("Figtree-Light.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}
